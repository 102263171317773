import { FC, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { SignIn } from "./pages/member/SignIn";
import { NotFound } from "./pages/NotFound";
import { TopPage } from "./pages/TopPage";
import { JoinPage } from "./pages/Join";
import { ContactPage } from "./pages/ContactPage";
import { GalleryPage } from "./pages/GalleryPage";
import { SecondVisitorPage } from "./pages/SecondVisitor";
import { MemberListPage } from "./pages/admin/MemberList";
import { ScheduleBoardPage } from "./pages/member/ScheduleBoard";
import { TrainingDayListPage } from "./pages/admin/TrainingDayListPage";
import { VisitorRequestListPage } from "./pages/admin/VisitorRequestList";
import { VisitorParticipationListPage } from "./pages/admin/VisitorParticipationListPage";
import { ParticipationHistoryPage } from "./pages/admin/ParticipationHistory";
import { CircleMemberListPage } from "./pages/admin/CiecleMemberList";

import { Navigate } from "react-router-dom";
import { getIsLoggedIn } from "./CookieHandler";
import { RedirectPage } from "./pages/member/RedirectPage";

export const Router: FC = () => {
  let [loggedIn, setIsLoggedIn] = useState(getIsLoggedIn());
  const loginPage = '/member/sign-in';
  const redirectPage = '/member/redirect';
  return (
    <Routes>
      {/* public */}
      <Route path="/" element={<TopPage />} />
      <Route path="/join" element={<JoinPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/second-visitor" element={<SecondVisitorPage />} />

      {/* member */}
      <Route path="/member/sign-in" element={<SignIn />} />
      <Route path="/member/redirect" element={<RedirectPage />} />
      <Route path="/member/schedule-board" element={loggedIn ? <ScheduleBoardPage /> : <Navigate replace to={loginPage} />} />
      <Route path="/pdj7hyxSbr/3ESdguLsrC.cgi" element={loggedIn ? <ScheduleBoardPage /> : <Navigate replace to={redirectPage} />} />
      <Route path="/pdj7hyxSbr/syukketu.cgi" element={loggedIn ? <ScheduleBoardPage /> : <Navigate replace to={redirectPage} />} />

      {/* admin */}
      <Route path="/admin/member-list" element={loggedIn ? <MemberListPage /> : <Navigate replace to={loginPage} />} />
      <Route path="/admin/training-day-list" element={loggedIn ? <TrainingDayListPage /> : <Navigate replace to={loginPage} />} />
      <Route path="/admin/visitor-request-list" element={loggedIn ? <VisitorRequestListPage /> : <Navigate replace to={loginPage} />} />
      <Route path="/admin/visitor-participation-list" element={loggedIn ? <VisitorParticipationListPage /> : <Navigate replace to={loginPage} />} />
      <Route path="/admin/participation-history" element={loggedIn ? <ParticipationHistoryPage /> : <Navigate replace to={loginPage} />} />
      <Route path="/admin/circle-member-list" element={loggedIn ? <CircleMemberListPage /> : <Navigate replace to={loginPage} />} />
      
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}