import { FC, useState, useEffect } from "react";
import { Header } from "../parts/Header";
import { SelectOption } from "../Model/SelectOption";
import Select from "react-select";
import { fetchWithoutSignAsync } from "../ApiHandler";
import { BoardItem } from "../Model/BoardItem";
import dayjs from "dayjs";
import { ToDateString } from "../utils/DateUtil";
import { postAsync } from "../ApiHandler";
import toast, { Toaster } from "react-hot-toast";

export const SecondVisitorPage: FC = () => {
  const [dateOptions, setDateOptions] = useState([] as SelectOption[]);
  const [isSent, setIsSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(undefined as SelectOption | undefined);

  useEffect(() => {
    const fetchList = async () => {
      const list = await fetchWithoutSignAsync<BoardItem[]>('TrainingDay/GetList', null);
      const options = [] as SelectOption[];
      for (var item of list) {
        const dateString = ToDateString(item.trainingDay!.date!);
        const hourString = `${item.trainingDay?.startTime} - ${item.trainingDay?.endTime}`
        const labelText = `${item.trainingPlace!.placeName} ${dateString} (${hourString})`;
        const option = { label: labelText, value: item.trainingDay?.date } as SelectOption;
        options.push(option);
      }
      setDateOptions(options);
    };

    fetchList();
  }, [])

  async function register() {
    if(isDisabled) { return; }
    const param = {
      email: email,
      date: selectedDate?.value ?? '',
    }
    
    setIsDisabled(true);
    const response = await postAsync<string>('VisitorParticipation/AddVisitorParticipation', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("送信しました");
    } else {
      toast.error(response.message ?? '');
    }
  }

  return <div>
    <Header></Header>
    <Toaster></Toaster>
    <div>
      <img src="/images/top-03.jpg" style={{ width: '100%' }}></img>
    </div>
    <div className="mt-8 mb-32 margin-auto" style={{ width: '400px' }}>
      { isSent && 
        <>
        <div className="border rounded px-2 py-2">参加希望を受け付けました。</div>
        </>
      }
      { !isSent && 
      <>
        <div className="mb-4">
        <div className="sub-title">参加希望の申し込み (2回目以降)</div>
        </div>

        <div className="px-2 py-2 mb-4" style={{ whiteSpace: 'pre-line', fontSize: '14px', border: '1px solid rgb(33, 150, 243)', borderRadius: '4px', color: 'rgb(33, 150, 243)' }}>
          参加メンバー数の調整の都合上、練習日の7日前から参加申し込みが可能となります。 <br></br>
          現状、参加人数多くなっているため、参加申し込みを受けつけられない場合があります。
        </div>

        <div className="text-gray-500">メールアドレス</div>
        <div className="text-gray-500 text-sm">(初参加時に入力したメールアドレスを入力してください)</div>
        <div className="mb-4">
          <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="border px-2 py-1 rounded w-full"></input>
        </div>

        <div className="text-gray-500">参加希望日</div>
        <div className="mb-8">
          <Select onChange={(e) => setSelectedDate(e!)} options={dateOptions} placeholder="選択してください"></Select>
        </div>
        <div>
          <button disabled={isDisabled} onClick={register} className="w-full border rounded py-2">送信</button>
        </div>
      </>
      }
    </div>

  </div>
}