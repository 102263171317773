import { FC, useState, useEffect } from "react";
import { postAsync } from "../../ApiHandler";
import { fetchAsync } from "../../ApiHandler";
import { BoardItem, BoardResponse } from "../../Model/BoardItem";
import { SelectOption } from "../../Model/SelectOption";
import toast, { Toaster } from "react-hot-toast";
import { Account } from "../../Model/Account";
import { MemberHeader } from "../../parts/MemberHeader";
import { ToMinutesString } from "../../utils/DateUtil";
import { Circle } from "../../Model/Circle";
import Select from "react-select";
import { TrainingPlace } from "../../Model/TrainingPlace";
import { ToDateString } from "../../utils/DateUtil";
import { Helmet } from "react-helmet";

class AddTradiningDayParam {
  date: string = '';
  placeId: string = '';
  startTime: string = '';
  endTime: string = '';
  placeIdOption: undefined | SelectOption;
  circleId: string = '';
  circleIdOption: undefined | SelectOption;
  setFormParam: string = '';
  maxMemberCount: string = '';
}

export const TrainingDayListPage: FC = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [mailDialogVisible, setMailDialogVisible] = useState(false);
  const [notificationDialogVisible, setNotificationDialogVisible] = useState(false);
  const [formParam, setFormParam] = useState({} as AddTradiningDayParam);
  const [isDisabled, setIsDisabled] = useState(false);
  const [version, setVersion] = useState(0);
  const [accountList, setAccountList] = useState([] as Account[]);
  const [board, setBoard] = useState(undefined as BoardResponse | undefined);
  const [circleOptions, setCircleOptions] = useState([] as SelectOption[]);
  const [placeOptions, setPlaceOptions] = useState([] as SelectOption[]);
  const [notification, setNotification] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [mailNotification, setMailNotification] = useState("");

  function onChabgeValue(name: string, value: string) {
    setFormParam(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const onChangeSelectValue = (event: SelectOption | null, name: string) => {
    setFormParam(prevState => ({
      ...prevState,
      [name]: new SelectOption(event!.label, event!.value)
    }));
  }

  async function sendMail(isTestMail: boolean) {
    if(isDisabled) { return; }
    const confirmText = isTestMail ? "自分自身にテストメールを送ります。" : "参加者全員(ビジター含む)にお知らせメールを送ります";
    if(!window.confirm(`${confirmText}よろしいですか？`)) { return; }

    var param = {
      date: selectedDate,
      notification: mailNotification,
      isTest: isTestMail
    };
    
    setIsDisabled(true);
    const response = await postAsync<string>('TrainingDay/SendBulkMail', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("メールを送信しました");
      setVersion(version + 1);
      if (!isTestMail) { setMailDialogVisible(false); }
    } else {
      toast.error(response.message ?? '');
    }
  }

  async function register() {
    if(isDisabled) { return; }
    formParam.circleId = formParam.circleIdOption?.value ?? '';
    formParam.placeId = formParam.placeIdOption?.value ?? '';
    setIsDisabled(true);
    const response = await postAsync<string>('TrainingDay/UpsertTrainingDay', formParam);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("登録しました");
      setFormParam({} as AddTradiningDayParam);
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  async function updateNotification() {
    if(isDisabled) { return; }
    var param = {
      key: 'NotificationText',
      value: notification,
    }
    setIsDisabled(true);
    const response = await postAsync<string>('KeyValue/Upsert', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("登録しました");
      setFormParam({} as AddTradiningDayParam);
      setNotificationDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  useEffect(() => {
    const fetchList = async () => {
      const list = await fetchAsync<BoardResponse>('TrainingDay/GetListForBoard', null);
      setBoard(list);
    };

    const fetchCircles = async () => {
      const list = await fetchAsync<Circle[]>('Circle/GetList', null);
      var options = [] as SelectOption[];
      for (var item of list) {
        options.push({ label: item.name, value: item.circleId } as SelectOption);
      }
      setCircleOptions(options);
    };

    const fetchNotification = async () => {
      const notification = await fetchAsync<string>('KeyValue/GetNotificationText', null);
      setNotification(notification);
    };

    const fetchPlaces = async () => {
      const list = await fetchAsync<TrainingPlace[]>('TrainingPlace/GetList', null);
      var options = [] as SelectOption[];
      for (var item of list) {
        options.push({ label: item.placeName, value: item.placeId } as SelectOption);
      }
      setPlaceOptions(options);
    };
  
    fetchList();
    fetchCircles();
    fetchPlaces();
    fetchNotification();
  }, [version]);

  function showMailDialog(date: string) {
    setMailDialogVisible(true);
    setSelectedDate(date);
  }

  return <>
    { mailDialogVisible &&
      <div className="bg-white pt-4 second-bg-color px-6 rounded-md second-bg-color input-dialog">
        <div className="mb-4 text-gray-600 text-sm inline-flex w-full justify-between">
          <span>メール通知</span>
          <img onClick={(e) => setMailDialogVisible(false)} style={{ width: '20px' }} className="hover-button" src="/images/icon-close.svg"></img>
        </div>
        <div className="mb-2">
          <span>対象日: {ToDateString(selectedDate)}</span>
        </div>
        <div className="mb-2">
          <textarea placeholder="(任意)メールに記載したい備考などあれば記載" value={mailNotification} onChange={e => setMailNotification(e.target.value)} className="w-full simple-input h-[100px] tex-sm"></textarea>
        </div>
        <div className="mb-6 mt-4">
          <button disabled={isDisabled} onClick={(e) => sendMail(true)} className="simple-button hover-button mr-4">自分にテストメール送信</button>
          <button disabled={isDisabled} onClick={(e) => sendMail(false)} className="simple-button hover-button">全体にメール送信</button>
        </div>
      </div>
    }

    { dialogVisible && 
    <div className="bg-white pt-4 second-bg-color px-6 rounded-md second-bg-color input-dialog">
      <div className="mb-4 text-gray-600 text-sm inline-flex w-full justify-between">
        <span>日程追加</span>
        <img onClick={(e) => setDialogVisible(false)} style={{ width: '20px' }} className="hover-button" src="/images/icon-close.svg"></img>
      </div>
      <div className="mb-2">
        <input type="date" onChange={(e) => onChabgeValue('date', e.target.value)} value={formParam.date} className="w-full simple-input"></input>
      </div>
      <div className="mb-2">
        <input type="text" onChange={(e) => onChabgeValue('startTime', e.target.value)} value={formParam.startTime} className="w-full simple-input" placeholder="開始時刻 (mm:dd)"></input>
      </div>
      <div className="mb-2">
        <input type="text" onChange={(e) => onChabgeValue('endTime', e.target.value)} value={formParam.endTime} className="w-full simple-input" placeholder="終了時刻 (mm:dd)"></input>
      </div>
      <div className="mb-2">
        <Select value={formParam.placeIdOption} onChange={(e) => onChangeSelectValue(e, 'placeIdOption')} options={placeOptions} placeholder="場所"></Select>
      </div>
      <div className="mb-2">
        <Select value={formParam.circleIdOption} onChange={(e) => onChangeSelectValue(e, 'circleIdOption')} options={circleOptions} placeholder="サークル"></Select>
      </div>
      <div className="mb-6">
        <input type="text" onChange={(e) => onChabgeValue('maxMemberCount', e.target.value)} value={formParam.maxMemberCount} className="w-full simple-input" placeholder="上限人数(外部サークルのみ)"></input>
      </div>
      <div className="mb-4">
        <button disabled={isDisabled} onClick={register} className="simple-button hover-button">追加</button>
      </div>
    </div>
    }

  { notificationDialogVisible && 
    <div className="bg-white pt-4 second-bg-color px-6 rounded-md second-bg-color input-dialog">
      <div className="mb-4 text-gray-600 text-sm inline-flex w-full justify-between">
        <span>お知らせ設定</span>
        <img onClick={(e) => setNotificationDialogVisible(false)} style={{ width: '20px' }} className="hover-button" src="/images/icon-close.svg"></img>
      </div>
      <div className="mb-2">
        <textarea value={notification} onChange={e => setNotification(e.target.value)} className="w-full simple-input h-[200px]"></textarea>
      </div>
      <div className="mb-4">
        <button disabled={isDisabled} onClick={updateNotification} className="simple-button hover-button">設定</button>
      </div>
    </div>
    }

    <Toaster></Toaster>
    <MemberHeader></MemberHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="pl-8 pt-4">
      <div className="inline-flex text-gray-700 items-end">
        <button className="simple-button mr-4" onClick={(e) => setDialogVisible(true)}>日程追加</button>
        <button className="simple-button" onClick={(e) => setNotificationDialogVisible(true)}>お知らせ設定</button>
      </div>
      <table className="data-table mt-4">
        <thead>
          <tr>
            <th>Mail</th>
            <th>Date</th>
            <th>Place</th>
            <th>From - To</th>
            <th>Mail Status</th>
            <th>Circle</th>
          </tr>
        </thead>
        <tbody>
          { board && board!.items!.map((e) => (
            <tr>
              <td><button onClick={(ele,) => showMailDialog(e.trainingDay?.date!)} className="simple-button ml-1">メール送信</button></td>
              <td>{ToDateString(e.trainingDay?.date!)}</td>
              <td>{e.trainingPlace?.placeName}</td>
              <td>{e.trainingDay?.startTime} - {e.trainingDay?.endTime}</td>
              <td>{e.trainingDay?.isSentMail === 1 ? '送信済' : '-----'}</td>
              <td>{e.circle?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
}